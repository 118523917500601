import axios from 'axios';
import { lmdService,userService } from 'Services/base';
import { RETURN_TYPE } from 'Utilities/constants/package';
import { urlParams } from 'Utilities';

export const createInwardLot = (type, packages) => {
  return lmdService.post('returninwardlot', {
    type,
    packages,
  });
};

export const markMissingPackage = packageObj => {
  return lmdService.post('returnlogisticpackage/', packageObj);
};

export const returnInwardReasons = () => {
  return lmdService.get('returninwardreasons');
};

export const returnPackageValidate = params => {
  return lmdService.get('returnpackagevalidate', { params });
};

export const returnFranchiseList = () => {
  return lmdService.get('lpreturnsoverview');
};

export const interstateFranchiseList = () => {
  return lmdService.get('interfcpackagesoverview');
};

export const franchiseLevelDetails = params => {
  return lmdService.get('returnlogisticsmanifests', { params });
};

export const interstateFranchiseLevelDetails = params => {
  return lmdService.get(`manifest`, { params });
};
export const returnFranchiseSearch = value => {
  return lmdService.get('lpreturnpagesearch', { params: { searchKey: value } });
};

export const createConsingment = packages => {
  return lmdService.post('returnmanifest', {
    type: RETURN_TYPE.FRANCHISE,
    packages,
  });
};

export const getActiveTransportList = () => {
  return lmdService.get('transport/', {
    params: {
      enabled: true,
    },
  });
};

export const getExecutiveList = () => {
  return lmdService.get('executive/');
};

export const getConfirmedConsignments = () => {
  return lmdService.get('returnlogisticsmanifests', {
    params: {
      type: RETURN_TYPE.INTERFC,
      status: 'created',
      fcType: 'source',
    },
  });
};

export const getDispatchedConsignments = (startDate, endDate) => {
  return lmdService.get('returnlogisticsmanifests', {
    params: {
      type: RETURN_TYPE.INTERFC,
      historyStatus: 'in_transit',
      fcType: 'source',
      from: startDate,
      to: endDate,
    },
  });
};

export const getToBeSentPackages = () => {
  return lmdService.get('returnlogisticspackages', {
    params: {
      type: RETURN_TYPE.INTERFC,
      status: 'returned_in_returnfc',
      fcType: 'source',
    },
  });
};


export const saathiReturnsPackages = () => {
  return lmdService.get('returnlogisticspackages', {
    params: {
      channel : 'Saathiself',
      statusList: 'pickup_scheduled,pickup_rescheduled,bilty_upload_pending,return_in_transit',
    },
  });
};

export const saathiReturnsPackage = () => {
  return lmdService.get('returnlogisticspackages', {
    params: {
      channel : 'Saathiself',
      statusList: 'pickup_scheduled,pickup_rescheduled,bilty_upload_pending,return_in_transit',
      fcType : 'returnpickup',
    },
  });
};


export const getToBeDispatchedConsignments = () => {
  return lmdService.get('returnlogisticsmanifests', {
    params: {
      type: RETURN_TYPE.INTERFC,
      status: 'executive_assigned',
      fcType: 'source',
    },
  });
};

export const getForwardChallanPrintData = params => {
  return lmdService.get('manifestdc/', { params });
};

export const getChallanPrintData = manifestId => {
  return lmdService.get('returnmanifestdc/', { params: { manifestId } });
};

export const getChallanPrintDataAndroid = (manifestId, authToken) => {
  let headers = {};
  headers['Content-Type'] = 'application/json';
  headers['X-Authorization-Token'] = authToken;

  return lmdService.get(
    `returnmanifestdc/?manifestId=${manifestId}&manifestType=FRANCHISE`,
    {
       headers,
    },
  );
};

export const validateAuthToken = authToken => {
  let headers = {};
  headers['Content-Type'] = 'application/json';
  headers['X-Authorization-Token'] = authToken;

  return userService.get('activeuser', {
   headers,
  });
};

export const updateReturnManifest = manifests => {
  return lmdService.post('returnmanifestupdate', { manifests });
};

export const getReturnPackageHistory = (
  type,
  startDate,
  endDate,
  fcType,
  historyStatus,
) => {
  const params = urlParams({
    type,
    from: startDate,
    to: endDate,
    fcType,
    historyStatus,
  });

  return lmdService.get('returnlogisticspackages', { params });
};

export const returnManifestAggregated = params => {
  return lmdService.get('returnlogisticsmanifestsaggregated', { params });
};

export const scanInterstatePackage = data => {
  return lmdService.put('validatereceivescan', data);
};

export const receiveInterstatePacakgesReceived = data => {
  return lmdService.post('packages', data);
};
